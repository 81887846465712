<template>
  <div class="abo-page flex-col">
    <div class="abo-group1 flex-col">
      <div class="abo-block1 flex-col">
        <div class="abo-bd1 flex-col">
          <headInfo :type="type"></headInfo>
          <span class="abo-txt3">助力仓储物流数智化</span>
          <span class="abo-word5">有效管理企业客户及销售过程，促进业务增长</span>
          <!-- <div class="abo-main3 flex-col" @click="toNewPage(5)"><span class="abo-txt4">立即试用</span></div> -->
        </div>
        <div class="abo-bd2 flex-col"><div class="abo-layer1 flex-col"></div></div>
      </div>
      <span class="abo-txt5">关于松云</span>
      <span class="abo-paragraph1"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;广州松云信息科技有限公司拥有超过15年软件系统研发经验，掌握制造、物流、电商和通信等领域知识，贯通软件、算法及设备，提供一体化解决方案。<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;从进行标准调研和流程梳理，形成架构和流程改造方案，指导后期平台研发；持续挖掘领域业务场景，数学建模算法整合，由局部到全局的持续优化。遵循PMI项目管理体系，执行严格的进度管理规范，工期可控，保证交付；驱动无人立库、AGV等设备，集成传感器、自控等物联网单元，高度智能化。<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;符合ISO9001质量管理体系，软件全生命周期管理，保障软件系统稳定健壮。</span
      >
      <div class="abo-block3 flex-row">
        <div class="abo-section5 flex-col"></div>
        <span class="abo-info1">使命</span>
        <div class="abo-section6 flex-col"></div>
        <span class="abo-word6">愿景</span>
        <div class="abo-section7 flex-col"></div>
        <span class="abo-word7">价值观</span>
      </div>
      <div class="abo-block4 flex-row justify-between">
        <span class="abo-word8">助力千行百业数字化转型</span>
        <span class="abo-info2">提供低成本高回报的企业信息系统</span>
        <span class="abo-txt6">客户导向 创新 专业 持续优化</span>
      </div>
      <span class="abo-txt7">企业历程</span>
      <div class="abo-block5 flex-row">
        <div class="abo-main4 flex-col"></div>
        <img
          class="abo-label1"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/dot.png"
        />
        <span class="abo-word9">ERP</span>
      </div>
      <div class="abo-block6 flex-row">
        <div class="abo-mod1 flex-col">
          <span class="abo-txt8">CRM</span>
          <span class="abo-txt9"
            >提供精细化公司筛选，同步用户管理，保存跟进结果</span
          >
          <span class="abo-word10">吉阳供应链</span>
          <span class="abo-info3"
            >为传统企业搭建数字化系统，有效管理非标件/标件等</span
          >
        </div>
        <div class="abo-mod2 flex-col justify-between">
          <div class="abo-layer2 flex-col"></div>
          <div class="abo-layer3 flex-col"></div>
        </div>
        <div class="abo-mod3 flex-col"></div>
        <div class="abo-mod4 flex-col justify-between">
          <img
            class="abo-label2"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/dot.png"
          />
          <img
            class="abo-icon1"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/dot.png"
          />
        </div>
        <div class="abo-mod5 flex-col">
          <span class="abo-word11"
            >供应商从潜在，评估，到正式交易及推出的全流程管理；销售和生产之间的订单流和客户服务信息集成</span
          >
          <span class="abo-word12">SONY-中国无人立库</span>
          <span class="abo-word13"
            >传统“人到货”模式转变为“货到人”模式，极大提高了出库效率，降低盘点错误率等</span
          >
          <span class="abo-word14">中船黄埔文冲物流</span>
          <span class="abo-word15">采用GPS+RFID作业，有效追踪物流订单处理情况</span>
        </div>
      </div>
      <div class="abo-block7 flex-row justify-between">
        <span class="abo-word16">熊猫游轮商城</span>
        <div class="abo-layer4 flex-col"></div>
      </div>
      <span class="abo-txt10">开启云商城，订单管理系统</span>
      <div class="abo-block8 flex-col">
        <div class="abo-bd3 flex-row justify-between">
          <div class="abo-bd4 flex-col">
            <div class="abo-main5 flex-col"></div>
            <img
              class="abo-pic1"
              referrerpolicy="no-referrer"
              src="@/assets/main/map.png"
            />
          </div>
          <div class="abo-bd5 flex-col">
            <span class="abo-word17">公司地址</span>
            <div class="abo-section1 flex-row justify-between">
              <div class="abo-mod7 flex-col"></div>
              <span class="abo-info4">联系电话：020&nbsp;-&nbsp;85596056</span>
            </div>
            <div class="abo-section2 flex-row justify-between">
              <div class="abo-box4 flex-col"></div>
              <span class="abo-word18"
                >联系地址：天河北路365号寰城中心广场2103</span
              >
            </div>
            <div class="abo-section3 flex-row justify-between">
              <div class="abo-wrap7 flex-col"></div>
              <span class="abo-word19">邮箱：support&#64;syinov.com</span>
            </div>
          </div>
        </div>
        <img
          class="abo-pic2"
          referrerpolicy="no-referrer"
          src="@/assets/images/back/grayital.png"
        />
      </div>
      <footInfo />
    </div>
    <div class="abo-group2 flex-col">
      <div class="abo-box3 flex-col"></div>
      <img
        class="abo-img2"
        referrerpolicy="no-referrer"
        src="@/assets/images/procedure/courseone.png"
      />
    </div>
    <img
      class="abo-label3"
      referrerpolicy="no-referrer"
      src="@/assets/images/procedure/dot.png"
    />
    <div class="abo-group3 flex-col">
      <div class="abo-group4 flex-col"></div>
      <img
        class="abo-pic3"
        referrerpolicy="no-referrer"
        src="@/assets/images/procedure/coursetwo.png"
      />
    </div>
    <div class="abo-group5 flex-col">
      <div class="abo-bd6 flex-col"></div>
      <img
        class="abo-img3"
        referrerpolicy="no-referrer"
        src="@/assets/images/procedure/coursethird.png"
      />
    </div>
    <div class="abo-group6 flex-col"></div>
    <div class="abo-group7 flex-col">
      <div class="abo-layer6 flex-col"></div>
      <img
        class="abo-pic4"
        referrerpolicy="no-referrer"
        src="@/assets/images/procedure/coursefour.png"
      />
    </div>
    <img
      class="abo-label4"
      referrerpolicy="no-referrer"
      src="@/assets/images/procedure/dot.png"
    />
    <div class="abo-group8 flex-col">
      <div class="abo-section4 flex-col"></div>
      <img
        class="abo-pic5"
        referrerpolicy="no-referrer"
        src="@/assets/images/procedure/coursefive.png"
      />
    </div>
    <div class="abo-group9 flex-col"></div>
    <div class="abo-group10 flex-col">
      <div class="abo-main11 flex-col"></div>
      <img
        class="abo-pic6"
        referrerpolicy="no-referrer"
        src="@/assets/images/procedure/coursesix.png"
      />
    </div>
    <img
      class="abo-label5"
      referrerpolicy="no-referrer"
      src="@/assets/images/procedure/dot.png"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 4,
      constants: {},
    };
  },
  methods: {
    onClick_1() {
      alert(1);
    },
    // 跳转页面
    toNewPage(index) {
      let url = "";
      // index的类型 0-主页 1-产品介绍 2-服务体系 3-典型案例 4-关于松云 5-预约注册
      switch (index) {
        case 0: //主页
          url = "/";
          break;
        case 1: // 产品介绍
          url = "/product/erp";
          break;
        case 2: // 服务体系
          url = "/system";
          break;
        case 3: //  典型案例
          url = "/instance/erp";
          break;
        case 4: //关于松云
          url = "/about";
          break;
        case 5: //预约注册
          url = "/register";
          break;
      }
      if (url == "") {
        return;
      }
      this.$router.push({ path: url });
    },
  },
};
</script>
<style  rel="stylesheet/scss" lang="scss" >
.abo-page {
  position: relative;
  width: 1920px;
  height: 4729px;
  text-align: center;
  margin: 0 auto;
  background-color: rgba(250, 250, 250, 1);
  overflow: hidden;
  .abo-group1 {
    width: 1920px;
    height: 4729px;
    .abo-block1 {
      // z-index: 7;
      height: 920px;
      overflow: hidden;
      // background: url(../../assets/main/graybig.png)
      //   100% no-repeat;
      width: 1920px;
      position: relative;
      .abo-bd1 {
        z-index: 80;
        width: 1920px;
        height: 610px;
        .abo-main1 {
          height: 100px;
          width: 1920px;
          .abo-main2 {
            width: 1775px;
            height: 42px;
            margin: 29px 0 0 65px;
            .abo-img1 {
              width: 180px;
              height: 38px;
              margin-top: 2px;
            }
            .abo-txt1 {
              width: 43px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 301px;
            }
            .abo-word1 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .abo-word2 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .abo-txt2 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .abo-word3 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiB;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .abo-block2 {
              height: 42px;
              border-radius: 21px;
              border: 2px solid rgba(37, 184, 47, 1);
              margin-left: 346px;
              width: 149px;
              .abo-word4 {
                width: 85px;
                height: 29px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 0.94);
                font-size: 21px;
                letter-spacing: 0.41999998688697815px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 29px;
                text-align: left;
                margin: 7px 0 0 32px;
              }
            }
          }
        }
        .abo-txt3 {
          width: 434px;
          height: 65px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 48px;
          letter-spacing: 0.9599999785423279px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 65px;
          text-align: right;
          margin: 221px 0 0 1286px;
        }
        .abo-word5 {
          width: 842px;
          height: 52px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 28px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 52px;
          text-align: right;
          margin: 36px 0 0 878px;
        }
        .abo-main3 {
          height: 68px;
          border-radius: 34px;
          background-color: rgba(37, 184, 47, 1);
          box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.32);
          width: 220px;
          margin: 68px 0 0 1500px;
          .abo-txt4 {
            width: 113px;
            height: 38px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 28px;
            letter-spacing: 0.5600000023841858px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 38px;
            text-align: left;
            margin: 15px 0 0 54px;
          }
        }
      }
      .abo-bd2 {
        z-index: 8;
        height: 920px;
        background: url(../../assets/main/backabout.png)
          100% no-repeat;
        width: 1920px;
        position: absolute;
        left: 0;
        top: 0;
        .abo-layer1 {
          width: 1920px;
          height: 920px;
          // background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    .abo-txt5 {
      width: 129px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(39, 39, 39, 1);
      font-size: 32px;
      letter-spacing: 0.6399999856948853px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 896px;
    }
    .abo-paragraph1 {
      width: 1262px;
      height: 180px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      line-height: 36px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 30px 0 0 329px;
    }
    .abo-block3 {
      width: 1140px;
      height: 278px;
      margin: 24px 0 0 391px;
      .abo-section5 {
        width: 268px;
        height: 268px;
        background: url(../../assets/images/procedure/aboutone.png) -1px -1px
          no-repeat;
      }
      .abo-info1 {
        width: 39px;
        height: 32px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 18px;
        letter-spacing: 1.5px;
        font-family: AlibabaPuHuiTiM;
        white-space: nowrap;
        line-height: 32px;
        text-align: center;
        margin: 246px 0 0 -154px;
      }
      .abo-section6 {
        width: 268px;
        height: 268px;
        background: url(../../assets/images/procedure/abouttwo.png) -1px -1px
          no-repeat;
        margin-left: 283px;
      }
      .abo-word6 {
        width: 39px;
        height: 32px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 18px;
        letter-spacing: 1.5px;
        font-family: AlibabaPuHuiTiM;
        white-space: nowrap;
        line-height: 32px;
        text-align: center;
        margin: 246px 0 0 -154px;
      }
      .abo-section7 {
        width: 268px;
        height: 268px;
        background: url(../../assets/images/procedure/aboutthird.png) -1px -1px
          no-repeat;
        margin-left: 283px;
      }
      .abo-word7 {
        width: 58px;
        height: 32px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 18px;
        letter-spacing: 1.5px;
        font-family: AlibabaPuHuiTiM;
        white-space: nowrap;
        line-height: 32px;
        text-align: center;
        margin: 246px 105px 0 -163px;
      }
    }
    .abo-block4 {
      // width: 1015px;
      width: 1081px;
      height: 32px;
      margin: 8px 0 0 430px;
      .abo-word8 {
        // width: 143px;
        height: 32px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
        letter-spacing: 1.3333333730697632px;
        font-family: AlibabaPuHuiTiR;
        line-height: 32px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .abo-info2 {
        // width: 170px;
        height: 32px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
        letter-spacing: 1.3333333730697632px;
        font-family: AlibabaPuHuiTiR;
        line-height: 32px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .abo-txt6 {
        // width: 143px;
        height: 32px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
        letter-spacing: 1.3333333730697632px;
        font-family: AlibabaPuHuiTiR;
        line-height: 32px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .abo-txt7 {
      width: 129px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(39, 39, 39, 1);
      font-size: 32px;
      letter-spacing: 0.6399999856948853px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 896px;
    }
    .abo-block5 {
      width: 190px;
      height: 30px;
      margin: 134px 0 0 1039px;
      .abo-main4 {
        width: 108px;
        height: 2px;
        background: url(../../assets/images/procedure/dashline.png)
          100% no-repeat;
        margin-top: 13px;
      }
      .abo-label1 {
        width: 30px;
        height: 30px;
        margin-left: 5px;
      }
      .abo-word9 {
        width: 33px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 16px;
        letter-spacing: 1.3333333730697632px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: left;
        margin: 6px 0 0 14px;
      }
    }
    .abo-block6 {
      width: 875px;
      height: 1201px;
      margin: 4px 0 0 531px;
      .abo-mod1 {
        width: 200px;
        height: 600px;
        margin-top: 228px;
        .abo-txt8 {
          width: 37px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: right;
          margin-left: 163px;
        }
        .abo-txt9 {
          width: 200px;
          height: 48px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 24px;
          text-align: right;
          margin-top: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .abo-word10 {
          width: 86px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: right;
          margin: 448px 0 0 114px;
        }
        .abo-info3 {
          width: 200px;
          height: 48px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 24px;
          text-align: right;
          margin-top: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .abo-mod2 {
        width: 108px;
        height: 526px;
        margin: 239px 0 0 41px;
        .abo-layer2 {
          width: 108px;
          height: 2px;
          background: url(../../assets/images/procedure/dashline.png)
            100% no-repeat;
        }
        .abo-layer3 {
          width: 108px;
          height: 2px;
          background: url(../../assets/images/procedure/dashline.png)
            100% no-repeat;
          margin-top: 522px;
        }
      }
      .abo-mod3 {
        width: 2px;
        height: 1142px;
        background: url(../../assets/images/back/solidline.png) -1px
          0px no-repeat;
        margin: 59px 0 0 79px;
      }
      .abo-mod4 {
        width: 30px;
        height: 554px;
        margin: 488px 0 0 191px;
        .abo-label2 {
          width: 30px;
          height: 30px;
        }
        .abo-icon1 {
          width: 30px;
          height: 30px;
          margin-top: 494px;
        }
      }
      .abo-mod5 {
        width: 210px;
        height: 1094px;
        margin-left: 14px;
        .abo-word11 {
          width: 200px;
          height: 96px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 24px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .abo-word12 {
          width: 159px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin-top: 398px;
        }
        .abo-word13 {
          width: 210px;
          height: 72px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 24px;
          text-align: left;
          margin-top: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .abo-word14 {
          width: 137px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin-top: 424px;
        }
        .abo-word15 {
          width: 200px;
          height: 48px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 24px;
          text-align: left;
          margin-top: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .abo-block7 {
      width: 252px;
      height: 24px;
      margin: 72px 0 0 628px;
      .abo-word16 {
        width: 103px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 16px;
        letter-spacing: 1.3333333730697632px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: right;
      }
      .abo-layer4 {
        width: 108px;
        height: 2px;
        background: url(../../assets/images/procedure/dashline.png)
          100% no-repeat;
        margin-top: 11px;
      }
    }
    .abo-txt10 {
      width: 200px;
      height: 24px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 14px;
      letter-spacing: 1.1666666269302368px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 24px;
      text-align: right;
      margin: 4px 0 0 531px;
    }
    .abo-block8 {
      z-index: 3;
      height: 754px;
      overflow: hidden;
      background: url(../../assets/images/back/grayone.png)
        100% no-repeat;
      width: 1919px;
      position: relative;
      margin: 155px 0 0 1px;
      .abo-bd3 {
        width: 1294px;
        height: 462px;
        margin: 128px 0 0 312px;
        .abo-bd4 {
          z-index: 63;
          height: 462px;
          overflow: hidden;
          background: url(../../assets/images/back/graytwo.png)
            100% no-repeat;
          width: 746px;
          position: relative;
          .abo-main5 {
            z-index: 65;
            position: static;
            left: 313px;
            top: 3576px;
            width: 746px;
            height: 462px;
            border: 12px solid rgba(203, 203, 203, 1);
          }
          .abo-pic1 {
            z-index: 64;
            position: absolute;
            left: 0;
            top: 0;
            width: 746px;
            height: 462px;
          }
        }
        .abo-bd5 {
          z-index: 63;
          width: 419px;
          height: 264px;
          margin-top: 96px;
          .abo-word17 {
            width: 129px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(39, 39, 39, 1);
            font-size: 32px;
            letter-spacing: 0.6399999856948853px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 44px;
            text-align: left;
          }
          .abo-section1 {
            width: 274px;
            height: 36px;
            margin: 52px 0 0 5px;
            .abo-mod7 {
              width: 20px;
              height: 20px;
              background: url(../../assets/images/procedure/phone.png) -1px
                0px no-repeat;
              margin-top: 9px;
            }
            .abo-info4 {
              width: 249px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(40, 40, 40, 1);
              font-size: 18px;
              letter-spacing: 1px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .abo-section2 {
            width: 413px;
            height: 36px;
            margin: 30px 0 0 6px;
            .abo-box4 {
              width: 18px;
              height: 20px;
              background: url(../../assets/images/procedure/located.png)
                0px -1px no-repeat;
              margin-top: 8px;
            }
            .abo-word18 {
              width: 389px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(40, 40, 40, 1);
              font-size: 18px;
              letter-spacing: 1px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .abo-section3 {
            width: 286px;
            height: 36px;
            margin: 30px 0 0 5px;
            .abo-wrap7 {
              width: 20px;
              height: 16px;
              background: url(../../assets/images/procedure/mail.png
              )
                0px 0px no-repeat;
              margin-top: 11px;
            }
            .abo-word19 {
              width: 261px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(40, 40, 40, 1);
              font-size: 18px;
              letter-spacing: 1px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
      .abo-pic2 {
        z-index: 4;
        position: absolute;
        left: 0;
        top: 0;
        width: 1919px;
        height: 754px;
      }
    }
    .abo-block10 {
      height: 527px;
      background-color: rgba(38, 38, 38, 1);
      width: 1920px;
      .abo-main6 {
        width: 1520px;
        height: 362px;
        margin: 81px 0 0 201px;
        .abo-main7 {
          width: 1015px;
          height: 36px;
          margin-left: 128px;
          .abo-txt11 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .abo-txt12 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 156px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .abo-txt13 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .abo-word20 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .abo-word21 {
            width: 87px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 21px;
            letter-spacing: 1px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 36px;
            text-align: left;
            margin-left: 388px;
          }
        }
        .abo-main8 {
          width: 1138px;
          height: 56px;
          margin: 20px 0 0 128px;
          .abo-box1 {
            width: 73px;
            height: 56px;
            .abo-info5 {
              width: 73px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .abo-word22 {
              width: 64px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .abo-box2 {
            width: 67px;
            height: 56px;
            margin-left: 121px;
            .abo-word23 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .abo-info6 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .abo-word24 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .abo-txt14 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .abo-word25 {
            width: 210px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 16px 0 0 359px;
          }
        }
        .abo-main9 {
          width: 1262px;
          height: 88px;
          margin: 8px 0 0 128px;
          .abo-wrap4 {
            width: 77px;
            height: 88px;
            .abo-txt15 {
              width: 68px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .abo-txt16 {
              width: 77px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
            .abo-word26 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .abo-wrap5 {
            width: 67px;
            height: 56px;
            margin-left: 117px;
            .abo-info7 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .abo-word27 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .abo-wrap6 {
            width: 334px;
            height: 84px;
            margin-left: 667px;
            .abo-txt17 {
              width: 334px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .abo-info8 {
              width: 220px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              margin-top: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .abo-info9 {
          width: 68px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 0.761904776096344px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 8px 0 0 128px;
        }
        .abo-word28 {
          width: 632px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 0.8);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 81px 0 0 443px;
        }
        .abo-main10 {
          width: 1520px;
          height: 1px;
          margin-top: 16px;
        }
      }
    }
  }
  .abo-group2 {
    // z-index: 253;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    background: url(../../assets/images/back/graycycle.png)
      100% no-repeat;
    width: 160px;
    position: absolute;
    left: 880px;
    top: 1868px;
    .abo-box3 {
      z-index: 1;
      position: static;
      left: 880px;
      top: 1868px;
      width: 160px;
      height: 160px;
      border: 4px solid rgba(37, 184, 47, 1);
      border-radius: 50%;
    }
    .abo-img2 {
      // z-index: 254;
      position: absolute;
      left: 0;
      top: 0;
      width: 160px;
      height: 160px;
    }
  }
  .abo-label3 {
    // z-index: 107;
    position: absolute;
    left: 743px;
    top: 2193px;
    width: 30px;
    height: 30px;
  }
  .abo-group3 {
    // z-index: 248;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    background: url(../../assets/images/back/graycycle.png)
      100% no-repeat;
    width: 160px;
    position: absolute;
    left: 880px;
    top: 2128px;
    .abo-group4 {
      z-index: 1;
      position: static;
      left: 880px;
      top: 2128px;
      width: 160px;
      height: 160px;
      border: 4px solid rgba(37, 184, 47, 1);
      border-radius: 50%;
    }
    .abo-pic3 {
      // z-index: 249;
      position: absolute;
      left: 0;
      top: 0;
      width: 160px;
      height: 160px;
    }
  }
  .abo-group5 {
    // z-index: 83;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    background: url(../../assets/images/back/graycycle.png)
      100% no-repeat;
    width: 160px;
    position: absolute;
    left: 880px;
    top: 2388px;
    .abo-bd6 {
      z-index: 1;
      position: static;
      left: 880px;
      top: 2388px;
      width: 160px;
      height: 160px;
      border: 4px solid rgba(37, 184, 47, 1);
      border-radius: 50%;
    }
    .abo-img3 {
      // z-index: 84;
      position: absolute;
      left: 0;
      top: 0;
      width: 160px;
      height: 160px;
    }
  }
  .abo-group6 {
    // z-index: 102;
    position: absolute;
    left: 1039px;
    top: 2469px;
    width: 108px;
    height: 2px;
    background: url(../../assets/images/procedure/dashline.png)
      100% no-repeat;
  }
  .abo-group7 {
    z-index: 78;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    background: url(../../assets/images/back/graycycle.png)
      100% no-repeat;
    width: 160px;
    position: absolute;
    left: 880px;
    top: 2648px;
    .abo-layer6 {
      z-index: 80;
      position: static;
      left: 880px;
      top: 2648px;
      width: 160px;
      height: 160px;
      border: 4px solid rgba(37, 184, 47, 1);
      border-radius: 50%;
    }
    .abo-pic4 {
      z-index: 79;
      position: absolute;
      left: 0;
      top: 0;
      width: 160px;
      height: 160px;
    }
  }
  .abo-label4 {
    // z-index: 110;
    position: absolute;
    left: 743px;
    top: 2717px;
    width: 30px;
    height: 30px;
  }
  .abo-group8 {
    z-index: 73;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    background: url(../../assets/images/back/graycycle.png)
      100% no-repeat;
    width: 160px;
    position: absolute;
    left: 880px;
    top: 2908px;
    .abo-section4 {
      z-index: 75;
      position: static;
      left: 880px;
      top: 2908px;
      width: 160px;
      height: 160px;
      border: 4px solid rgba(37, 184, 47, 1);
      border-radius: 50%;
    }
    .abo-pic5 {
      z-index: 74;
      position: absolute;
      left: 0;
      top: 0;
      width: 160px;
      height: 160px;
    }
  }
  .abo-group9 {
    // z-index: 103;
    position: absolute;
    left: 1039px;
    top: 2993px;
    width: 108px;
    height: 2px;
    background: url(../../assets/images/procedure/dashline.png)
      100% no-repeat;
  }
  .abo-group10 {
    z-index: 68;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    background: url(../../assets/images/back/graycycle.png)
      100% no-repeat;
    width: 160px;
    position: absolute;
    left: 880px;
    top: 3168px;
    .abo-main11 {
      z-index: 70;
      position: static;
      left: 880px;
      top: 3168px;
      width: 160px;
      height: 160px;
      border: 4px solid rgba(37, 184, 47, 1);
      border-radius: 50%;
    }
    .abo-pic6 {
      z-index: 69;
      position: absolute;
      left: 0;
      top: 0;
      width: 160px;
      height: 160px;
    }
  }
  .abo-label5 {
    // z-index: 113;
    position: absolute;
    left: 743px;
    top: 3238px;
    width: 30px;
    height: 30px;
  }
}

</style>
<style src="../../assets/css/common.css" />
