var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abo-page flex-col"},[_c('div',{staticClass:"abo-group1 flex-col"},[_c('div',{staticClass:"abo-block1 flex-col"},[_c('div',{staticClass:"abo-bd1 flex-col"},[_c('headInfo',{attrs:{"type":_vm.type}}),_c('span',{staticClass:"abo-txt3"},[_vm._v("助力仓储物流数智化")]),_c('span',{staticClass:"abo-word5"},[_vm._v("有效管理企业客户及销售过程，促进业务增长")])],1),_vm._m(0)]),_c('span',{staticClass:"abo-txt5"},[_vm._v("关于松云")]),_vm._m(1),_vm._m(2),_vm._m(3),_c('span',{staticClass:"abo-txt7"},[_vm._v("企业历程")]),_vm._m(4),_vm._m(5),_vm._m(6),_c('span',{staticClass:"abo-txt10"},[_vm._v("开启云商城，订单管理系统")]),_vm._m(7),_c('footInfo')],1),_vm._m(8),_c('img',{staticClass:"abo-label3",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/dot.png")}}),_vm._m(9),_vm._m(10),_c('div',{staticClass:"abo-group6 flex-col"}),_vm._m(11),_c('img',{staticClass:"abo-label4",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/dot.png")}}),_vm._m(12),_c('div',{staticClass:"abo-group9 flex-col"}),_vm._m(13),_c('img',{staticClass:"abo-label5",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/dot.png")}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abo-bd2 flex-col"},[_c('div',{staticClass:"abo-layer1 flex-col"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"abo-paragraph1"},[_vm._v("      广州松云信息科技有限公司拥有超过15年软件系统研发经验，掌握制造、物流、电商和通信等领域知识，贯通软件、算法及设备，提供一体化解决方案。"),_c('br'),_vm._v("      从进行标准调研和流程梳理，形成架构和流程改造方案，指导后期平台研发；持续挖掘领域业务场景，数学建模算法整合，由局部到全局的持续优化。遵循PMI项目管理体系，执行严格的进度管理规范，工期可控，保证交付；驱动无人立库、AGV等设备，集成传感器、自控等物联网单元，高度智能化。"),_c('br'),_vm._v("      符合ISO9001质量管理体系，软件全生命周期管理，保障软件系统稳定健壮。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abo-block3 flex-row"},[_c('div',{staticClass:"abo-section5 flex-col"}),_c('span',{staticClass:"abo-info1"},[_vm._v("使命")]),_c('div',{staticClass:"abo-section6 flex-col"}),_c('span',{staticClass:"abo-word6"},[_vm._v("愿景")]),_c('div',{staticClass:"abo-section7 flex-col"}),_c('span',{staticClass:"abo-word7"},[_vm._v("价值观")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abo-block4 flex-row justify-between"},[_c('span',{staticClass:"abo-word8"},[_vm._v("助力千行百业数字化转型")]),_c('span',{staticClass:"abo-info2"},[_vm._v("提供低成本高回报的企业信息系统")]),_c('span',{staticClass:"abo-txt6"},[_vm._v("客户导向 创新 专业 持续优化")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abo-block5 flex-row"},[_c('div',{staticClass:"abo-main4 flex-col"}),_c('img',{staticClass:"abo-label1",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/dot.png")}}),_c('span',{staticClass:"abo-word9"},[_vm._v("ERP")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abo-block6 flex-row"},[_c('div',{staticClass:"abo-mod1 flex-col"},[_c('span',{staticClass:"abo-txt8"},[_vm._v("CRM")]),_c('span',{staticClass:"abo-txt9"},[_vm._v("提供精细化公司筛选，同步用户管理，保存跟进结果")]),_c('span',{staticClass:"abo-word10"},[_vm._v("吉阳供应链")]),_c('span',{staticClass:"abo-info3"},[_vm._v("为传统企业搭建数字化系统，有效管理非标件/标件等")])]),_c('div',{staticClass:"abo-mod2 flex-col justify-between"},[_c('div',{staticClass:"abo-layer2 flex-col"}),_c('div',{staticClass:"abo-layer3 flex-col"})]),_c('div',{staticClass:"abo-mod3 flex-col"}),_c('div',{staticClass:"abo-mod4 flex-col justify-between"},[_c('img',{staticClass:"abo-label2",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/dot.png")}}),_c('img',{staticClass:"abo-icon1",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/dot.png")}})]),_c('div',{staticClass:"abo-mod5 flex-col"},[_c('span',{staticClass:"abo-word11"},[_vm._v("供应商从潜在，评估，到正式交易及推出的全流程管理；销售和生产之间的订单流和客户服务信息集成")]),_c('span',{staticClass:"abo-word12"},[_vm._v("SONY-中国无人立库")]),_c('span',{staticClass:"abo-word13"},[_vm._v("传统“人到货”模式转变为“货到人”模式，极大提高了出库效率，降低盘点错误率等")]),_c('span',{staticClass:"abo-word14"},[_vm._v("中船黄埔文冲物流")]),_c('span',{staticClass:"abo-word15"},[_vm._v("采用GPS+RFID作业，有效追踪物流订单处理情况")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abo-block7 flex-row justify-between"},[_c('span',{staticClass:"abo-word16"},[_vm._v("熊猫游轮商城")]),_c('div',{staticClass:"abo-layer4 flex-col"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abo-block8 flex-col"},[_c('div',{staticClass:"abo-bd3 flex-row justify-between"},[_c('div',{staticClass:"abo-bd4 flex-col"},[_c('div',{staticClass:"abo-main5 flex-col"}),_c('img',{staticClass:"abo-pic1",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/main/map.png")}})]),_c('div',{staticClass:"abo-bd5 flex-col"},[_c('span',{staticClass:"abo-word17"},[_vm._v("公司地址")]),_c('div',{staticClass:"abo-section1 flex-row justify-between"},[_c('div',{staticClass:"abo-mod7 flex-col"}),_c('span',{staticClass:"abo-info4"},[_vm._v("联系电话：020 - 85596056")])]),_c('div',{staticClass:"abo-section2 flex-row justify-between"},[_c('div',{staticClass:"abo-box4 flex-col"}),_c('span',{staticClass:"abo-word18"},[_vm._v("联系地址：天河北路365号寰城中心广场2103")])]),_c('div',{staticClass:"abo-section3 flex-row justify-between"},[_c('div',{staticClass:"abo-wrap7 flex-col"}),_c('span',{staticClass:"abo-word19"},[_vm._v("邮箱：support@syinov.com")])])])]),_c('img',{staticClass:"abo-pic2",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/back/grayital.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abo-group2 flex-col"},[_c('div',{staticClass:"abo-box3 flex-col"}),_c('img',{staticClass:"abo-img2",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/courseone.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abo-group3 flex-col"},[_c('div',{staticClass:"abo-group4 flex-col"}),_c('img',{staticClass:"abo-pic3",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/coursetwo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abo-group5 flex-col"},[_c('div',{staticClass:"abo-bd6 flex-col"}),_c('img',{staticClass:"abo-img3",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/coursethird.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abo-group7 flex-col"},[_c('div',{staticClass:"abo-layer6 flex-col"}),_c('img',{staticClass:"abo-pic4",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/coursefour.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abo-group8 flex-col"},[_c('div',{staticClass:"abo-section4 flex-col"}),_c('img',{staticClass:"abo-pic5",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/coursefive.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abo-group10 flex-col"},[_c('div',{staticClass:"abo-main11 flex-col"}),_c('img',{staticClass:"abo-pic6",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/coursesix.png")}})])
}]

export { render, staticRenderFns }